import React from 'react';

const IconArrowDown = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      id="Group_47"
      data-name="Group 47"
      viewBox="0 0 11.27 7.55"
      width="20"
      height="20"
    >
      <defs>
        <path
          id="Shape"
          fill="#005c84"
          d="M11.3.76a.96.96 0 0 0-1.41 0L6 4.95 2.12.75A.96.96 0 0 0 .7.76L.63.84a1 1 0 0 0 0 1.36L6 8l5.37-5.8a1 1 0 0 0 0-1.36Z"
        />
      </defs>
      <defs>
        <clipPath id="clip-path-arrow-down">
          <use xlinkHref="#Shape" transform="translate(-.36 -.45)" />
        </clipPath>
      </defs>
      <use
        xlinkHref="#Shape"
        id="Shape-2"
        data-name="Shape"
        transform="translate(-.36 -.45)"
      />
      <g
        id="Mask_Group_47"
        clip-path="url(#clip-path-arrow-down)"
        data-name="Mask Group 47"
      >
        <g id="Color" transform="translate(-5.36 -7.45)">
          <path id="Rectangle" fill="#005c84" d="M0 0h22v22H0z" />
        </g>
      </g>
    </svg>
  );
};

export default IconArrowDown;
