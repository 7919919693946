import React, { useState } from 'react';
import Button from '../Button/Button';
import Select from '../Select/Select';
import IconLogo from '../Layout/Icons/IconLogo';
import IconSwirlLarge from '../Layout/Icons/IconSwirlLarge';
import style from './splash.mod.scss';

const Splash = ({ onContinue }) => {
  
  const [region, setRegion] = useState(null);
  const [locale, setLocale] = useState(null);
  const [langOptions, setLangOptions] = useState([]);
  
  const regions = [
    {
      title: 'Australia',
      locales: [{title: 'English', code: 'en-AU'}]
    },
    {
      title: 'New Zealand',
      locales: [{title: 'English', code: 'en-NZ'}]
    },
    {
      title: 'United Kingdom',
      locales: [{title: 'English', code: 'en-GB'}]
    },
    {
      title: 'Europe',
      locales: [{title: 'English', code: 'en-EU'}]
    },
  ];
  
  const onSelectCountry = (v) => {
    // console.log("onSelectCountry", v);
    setRegion(v);
    setLocale(null);
    let opts = [];
    if (v && v !== null) {
      let selectedRegion = regions.find(o => o.title === v);
      if (selectedRegion && selectedRegion.locales && selectedRegion.locales.length > 0) {
        opts = selectedRegion.locales.map(l => `${l.title} (${l.code})`);
      }
    }
    setLangOptions(opts);
  }
  
  const onSelectLang = (v) => {
    // console.log("onSelectLang", v);
    if (v) {
      let s = v.substr(-6, 5);
      setLocale(s);
    }
  }
  
  return (
    <div className={style.splash}>
      <span className={style.splash__logo}>
        <IconLogo />
      </span>
      <div className={style.splash__form}>
        <p className={style.splash__subheading}>Choose your country and language</p>
        <Select className={style.splash__select} items={regions.map(region => region.title)} getFilterSelect={onSelectCountry} noSelectionLabel="Select Country" />
        <Select key={region || "none"} className={style.splash__select} items={langOptions} getFilterSelect={onSelectLang} noSelectionLabel="Select Language" />
        <Button type="button" fullWidth className={style.splash__continueButton} onClick={() => { onContinue(locale); }} disabled={!locale}>Continue</Button>
      </div>
      <span className={style.splash__swirl}>
        <IconSwirlLarge />
      </span>
    </div>
  );
}

export default Splash;
